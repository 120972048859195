@import '../../styles/colors.scss';

.Home {
  display: grid;
  align-self: flex-start;
  justify-self: center;
  align-self: stretch;
}

.Home .ImageContainer {
  justify-self: center;
  align-self: flex-start;
  padding-top: 8px;
  grid-row: 1/2;
  grid-column: 1/2;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content;
  padding-left: 0px;
}

.Home .ImageContainer > .color {
  width: 100%;
  transform: rotateZ(180deg);
  display: none;
}

.Home .ImageContainer .couple {
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: end;
  width: 100%;
}

.Home .ImageContainer .fade {
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: end;
  width: 100%;
  background: radial-gradient(circle, rgba(255,255,255,0) 70%, rgba(255,255,255,0.85) 90%, rgba(255,255,255,1) 100%);
}

.Home > .ImageContainer > .SaveTheDate {
  color: $primary-dark;
  font-size: 3em;
  grid-row: 2/3;
  grid-column: 1/2;
  display: grid;
  justify-self: stretch;
  justify-items: space-between;
  align-self: center;
  font-family: 'Liu Jian Mao Cao', cursive;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.3em;
  width: 100%;
}

.Home > .ImageContainer > .SaveTheDate > .start {
  white-space: nowrap;
  justify-self: end;
}

.Home > .ImageContainer > .SaveTheDate > .middle {
  justify-self: start;
}

.Home > .ImageContainer > .SaveTheDate > .end {
  grid-column: 1/3;
  justify-self: center;
}

// .Home > .ImageContainer > .date {
//   align-self: flex-end;
//   justify-self: end;
//   color: $primary;
//   font-size: 2.2em;
//   grid-column: 1/2;
// }

.Home .ImageContainer .CountDown {
  padding-top: 0.4em;
  align-self: flex-start;
  justify-self: center;
  grid-template-columns: repeat(4, 1.4em);
  column-gap: 0.3em;
  color: $primary-dark;
  font-size: 3em;
  grid-column: 1/2;
}

.Home .ImageContainer .CountDown .info_text {
  font-size: 0.27em;
  justify-self: start;
}

.Home .ImageContainer .CountDown .days_text {
  padding-left: 0.8em;
}

.Home .ImageContainer .CountDown .days,
.Home .ImageContainer .CountDown .hours,
.Home .ImageContainer .CountDown .minutes {
  padding-right: 0.3em;
  border-right: 0.5px solid;
}

.Home .ImageContainer .color {
  align-self: flex-end;
  grid-column: 2/3;
  grid-row: 2/4;
  width: 100%;
}

@supports (-webkit-touch-callout: none) {
}

@media only screen and (min-width: 755px) {
  .Home .ImageContainer .color {
    display: block;
  }

  .Home .ImageContainer {
    grid-template-columns: 60px minmax(33%, 1fr) 2fr 60px;
    grid-template-rows: min-content min-content 1fr;
    row-gap: 5em;
    height: 100%;
    width: 100%;
  }
  .Home .ImageContainer > .color:first-child {
    grid-row: 1/2;
    grid-column: 1/2;
    align-self: flex-start;
    justify-self: flex-end;
  }
  .Home .ImageContainer .couple {
    grid-column: 2/3;
    grid-row: 1/3;
    width: inherit;
  }
  .Home .ImageContainer .fade {
    grid-column: 2/3;
    grid-row: 1/3;
  }
  .Home > .ImageContainer > .SaveTheDate {
    grid-column: 3/4;
    grid-row: 1/2;
    align-self: flex-start;
    padding-top: 0.2em;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    font-size: 10vw;
  }
  .Home > .ImageContainer > .SaveTheDate > .start {
    grid-column: 1/2;
    justify-self: center;
  }

  .Home > .ImageContainer > .SaveTheDate > .middle {
    grid-column: 1/2;
    justify-self: center;
  }

  .Home > .ImageContainer > .SaveTheDate > .end {
    grid-column: 1/2;
    justify-self: center;
  }

  .Home .ImageContainer > .color:last-child {
    grid-column: 4/5;
    grid-row: 1/3;
    align-self: end;
    transform: rotateZ(0deg);
  }

  .Home .ImageContainer .CountDown {
    padding-top: 0;
    grid-row: 2/3;
    grid-column: 3/4;
    align-self: flex-end;
    grid-template-columns: repeat(4, 1.4em);
    column-gap: 0.3em;
    font-size: 4em;
    grid-template-rows: 1fr 0.5em;
  }
}

@media only screen and (min-width: 1280px) {
  .Home > .ImageContainer > .SaveTheDate {
    font-size: 140px;
  }
}