@import './styles/colors.scss';

.App {
  display: grid;
  background-color: $background-color;
  overflow: hidden;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(0px, 8px) 1fr minmax(0px, 8px);
  min-height: 100vh;
  color: $white-text;
}

.App > section {
  grid-column: 2/3;
  grid-row: 1/2;
  padding-top: 44px;
}


.notification-item {
  background-color: $primary;
  border-left-color: $primary-dark;
}

@supports (-webkit-touch-callout: none) {
  .App > section {
    padding: 44px
             env(safe-area-inset-right)
             56px
             env(safe-area-inset-left);
  }
  @media only screen and (display-mode: standalone) {
    .App > section {
      padding-top: 88px; /* +44 */
      padding-bottom: 83px; /* +27 */
    }
  }
}
@media only screen and (max-width: 755px) {
  .App > section {
    padding-bottom: 56px;
  }
}

@media only screen and (min-width: 755px) {
  .App {
    grid-template-rows: 1fr 50px;
    grid-template-columns: minmax(0px, 1fr) minmax(755px, 1280px) minmax(0px, 1fr);
  }
  .App > section {
    padding-top: 150px;
  }
}

@media only screen and (max-width: 600px) {
  .App > h1 {
    font-size: 1.2em;
  }
}

@media only screen and (max-width: 940px) {
  .App > h1 {
    font-size: 3em;
  }
}


