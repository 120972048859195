@import '../../styles/colors.scss';

.NavBar {
  background-color: $primary;
  height: 56px;
  width: 100%;
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}

.NavBar a {
  height: 56px;
  width: 56px;
  display: grid;
  grid-template-areas: 
  '.'
  'logo'
  'text'
  '.';
  grid-template-rows: 8px 24px 12px 8px;
  justify-items: center;
  align-items: center;
  align-content: center;
  color: $primary-text-dark;
}

.NavBar a.current {
  color: $primary-text;
}

.NavBar a i {
  grid-area: logo;
}

.NavBar a p {
  grid-area: text;
}

@supports (-webkit-touch-callout: none) {
  @media only screen and (display-mode: standalone) {
  }
  .NavBar {
    height: 83px;
  }
}

@media only screen and (min-width: 755px) {
  .NavBar {
    display: none;
  }
}