@import '../../styles/colors.scss';

.Gallery {
  display: grid;
}

.Gallery > div {
  padding-top: 120px;
  justify-self: center;
  text-align: center;
  font-size: 5em;
  font-family: 'Oswald', sans-serif;
}