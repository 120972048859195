@import '../../styles/colors.scss';

.Rsvp {
  margin-bottom: 32px;
  width: 100%;
}

.Rsvp .MuiFormControl-root {
  width: 100%;
}

.Rsvp .Title {
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
}

.Rsvp .Subtitle {
  font-size: 0.5em;
  color: rgba(0, 0, 0, 0.87);
}

.Rsvp #date::-webkit-inner-spin-button {
  display: none;
}

.Rsvp #date::-webkit-clear-button {
  display: none;
}

.Rsvp #date::-webkit-calendar-picker-indicator  {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

@media only screen and (min-width: 755px) {
  .Rsvp .MuiGrid-item {
    width: 80%;
  }
}