@import './styles/colors.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* transform: scale(0.9);
  transform-origin: 50% 0; */
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Hide scrollbar*/
body {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  /* -ms-overflow-style: -ms-autohiding-scrollbar; */
  font-family: 'Roboto Monot', sans-serif;
}
body::-webkit-scrollbar { /* WebKit */
  height: 0;
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
body::-webkit-scrollbar-thumb {
  background: #FF0000;
}

a {
  text-decoration: none;
  outline: 0;
}
a:visited {
  text-decoration: none;
  color: inherit;
}
a:link {
  text-decoration: none;
  color: inherit;
}
a:link:hover {
  text-decoration: none;
  color: $primary-dark;
}