@import '../../styles/colors.scss';

section.Tips {
  display: grid;
  background-position: -20em center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  grid-template-columns: 1.5fr 1fr;
  // margin-right: 8em;
}

.Tips div {
  background-color: $background-color;
  justify-self: stretch;
  font-family: 'Dancing Script';
  padding-left: 8px;
  margin-left: 8em;
  align-self: center;

  color: $white-text;
  background-color: hsla(0,0%,100%,.75);
}

.Tips div a {
  text-decoration: underline;
}

.Tips div p {
  font-size: 1em;
  font-family: 'Roboto';
  font-style: italic;
  /* font-family: 'Trade Winds'; */
}

.Tips div img {
  width: 100%;
  display: none;
}

@media only screen and (max-width: 1250px) {
  .Tips div {
    margin-left: 2em;
  }
  section.Tips {
    background-position: -30em center !important;
    // margin-right: 2em;
  }
}

@media only screen and (max-width: 1060px) {
  .Tips div {
    margin-left: 2em;
  }
  section.Tips {
    background-position: -40em center !important;
    // margin-right: 2em;
  }
}

@media only screen and (max-width: 960px) {
  .Tips div {
    margin-left: 2em;
  }
  section.Tips {
    background-position: -50em center !important;
    // margin-right: 2em;
  }
}

@media only screen and (max-width: 910px) {
  .Tips div {
    margin-left: 2em;
  }
  section.Tips {
    background-position: -55em center !important;
    // margin-right: 2em;
  }
}

@media only screen and (max-width: 790px) {
  .Tips div {
    margin-left: 2em;
  }
  section.Tips {
    background-position: -55em center !important;
    // margin-right: 2em;
  }
}

@media only screen and (orientation: portrait) and (max-width: 755px) {
  section.Tips {
    background: none !important;
  }
}

@media only screen and (max-width: 755px) {
  .Tips div {
    margin-left: unset;
    align-self: stretch;
    color: $white-text;
    background-color: rgba(255, 255, 255, 0.826);
  }
  section.Tips {
    background: none !important;
    grid-template-columns: 1fr;
  }

  .Tips div img {
    display: inherit;
  }
}