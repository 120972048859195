@import '../../styles/colors.scss';

.MenuBar {
  list-style-type: none;
  grid-column: 2/3;
  grid-row: 1/2;
  height: 150px;
  width: 100%;
  z-index: 10;
  font-family: 'Oswald', sans-serif;
  display: grid;
  justify-content: stretch;
  align-items: center;
  grid-template-columns: minmax(0px, 236px) 1fr minmax(0px, 236px);
  grid-template-rows: 150px;
}

.MenuBar > .rsvp {
  position: absolute;
  top: 0px;
  right: 220px;
  color: $primary-text;
  width: 40px;
  height: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2fr 1fr;
  cursor: pointer;
  transition: height 0.2s ease-out;
  z-index: 12;
}
.MenuBar > .rsvp:hover {
  height: 60px;
}
.MenuBar > .rsvp a:link:hover {
  text-decoration: none;
  color: $primary-text-light;
}

.MenuBar > .rsvp .text{
  grid-column: 1/3;
  justify-self: stretch;
  align-self: stretch;
  text-align: center;
  background-color: $primary;
  padding-top: 4px;
}

.MenuBar > .rsvp .left{
  border-top: 16px solid $primary;
  border-right: 20px solid transparent;
}

.MenuBar > .rsvp .right{
  border-top: 16px solid $primary;
  border-left: 20px solid transparent;
}

.MenuBar.transparent {
  background: rbga(0,0,0,0.5);
  color: $white-text;
}

.MenuBar.solid {
  background-color: $background-color;
  color: $white-text;
}

.MenuBar.fixed {
  position: fixed;
  transition-property: top;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  background-color: $background-color;
  color: $white-text;
  height: 80px;
  grid-template-rows: 80px;
}

.MenuBar.relative {
  position: relative;
}

.MenuBar > .top_left {
  display: grid;
  height: 100%;
  align-self: stretch;
  grid-column: 1/2;
  grid-row: 1/2;
  pointer-events: none;
}

.MenuBar > .top_right {
  display: grid;
  height: 100%;
  align-self: stretch;
  grid-column: 3/4;
  grid-row: 1/2;
  pointer-events: none;
}

.MenuBar > .top_left > img {
  width: 100%;
  transform: rotateX(155deg);
  position: relative;
  top: 30px;
}

.MenuBar.fixed > .top_left > img {
  top:15px;
  left: 50px;
}

.MenuBar > .top_right > img {
  width: 140%;
  transform: rotate(20deg);
  position: relative;
  top: -30px;
  // right: -40px;
  justify-self: flex-end;
}

.MenuBar.fixed > .top_right > img {
  top: -10px;
}

.MenuBar > ul {
  grid-row: 1/2;
  grid-column: 1/4;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  color: inherit;
  height: 150px;
  overflow: hidden;
  z-index: 11;
}

.MenuBar.fixed > ul {
  height: 80px;
}

.MenuBar > ul > li {
  display: flex;
  align-items: center;
  margin-right: 18px;
  color: inherit;
  text-transform: uppercase;
  font-size: 0.99em;
  padding-top: 88px;
  /* padding-bottom: 18px; */
  overflow: hidden;

  color: $white-text;
}

.MenuBar.fixed > ul > li {
  padding-top: 38px;
}

.MenuBar > ul > li > .heartContainer {
  margin-right: 12px;
  text-decoration: none;
}

.MenuBar > ul > li > a > .flowerTextContainer {
  transform: translate(0,-50px);
}

.MenuBar.fixed > ul > li > a > .flowerTextContainer {
  transform: translate(0,-25px);
}


.MenuBar > ul > li > a {
  text-decoration: none;
  outline: 0;
}

.MenuBar > ul > li > a:visited {
  text-decoration: none;
  color: inherit;
}
.MenuBar > ul > li:hover {
  text-decoration: none;
  color: $primary;
  cursor: pointer;
}
.MenuBar > ul > li > a:link {
  text-decoration: none;
  color: inherit;
}
.MenuBar > ul > li > a:link:hover {
  text-decoration: none;
  color: $primary;
}

.MenuBar > ul > li > a.current {
  color: $primary;
}

.MenuBar .AccountButton {
}

.MenuBar .AccountButton > div {
  display: none;
  position: absolute;
}

.MenuBar .AccountButton:hover > div {
  display: block;
}

.MenuBar .AccountButton:focus-within > div {
  display: block;
}

@media only screen and (max-width: 955px) {
  .MenuBar > .top_right > img {
    height: 100%;
    top: 30px;
    // right: -10px;
  }

  .MenuBar.fixed > .top_right > img {
    top: 15px;
    // right: -40px;
  }
}

@media only screen and (max-width: 890px) {
  .MenuBar > .top_left > img {
    height: 55%;
    top: 71px;
    left: -30px;
  }
  .MenuBar > .top_right > img {
    height: 55%;
    top: 80px;
  }
}

@media only screen and (max-width: 755px) {
  .MenuBar {
    display: none;
  }
}