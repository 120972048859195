@import '../../styles/colors.scss';

.flowerTextContainer {
  display: grid;
  grid-template-rows: max-content;
  grid-template-columns: max-content;
  width: 100%;
}

.flowerTextContainer > img {
  grid-row: 1/2;
  grid-column: 1/2;
  align-self: stretch;
  justify-self: stretch;
  transform: translate(0,-15%);
}
.flowerTextContainer > span {
  grid-row: 1/2;
  grid-column: 1/2;
  align-self: center;
  justify-self: center;
  font-family: 'Parisienne', cursive;
  font-weight: initial;
  color: $flower-text-color;
  position: relative;
  top: -5%;
}
.flowerTextContainer > span:last-child {
  top: 10%;
}