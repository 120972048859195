@import '../../styles/colors.scss';

section.FooterBar {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  display: none;
  grid-template-columns: 1fr 1fr 8fr max-content;
  column-gap: 16px;
  padding: 0 16px 0 16px;
  align-items: center;
  position: fixed;
  bottom: 0px;
  height: 50px;
  color: white;
  text-shadow: 1px 1px #000000;
  z-index: 10;
}

.FooterBar a {
  display: flex;
  align-items: center;
}
.FooterBar > a > .heartContainer {
  margin-right: 4px;
}

@media only screen and (min-width: 755px) {
  section.FooterBar {
    display: grid;
    grid-row: 2/3;

    position: inherit;
    bottom: inherit;
    color: inherit;
    text-shadow: none;
    z-index: inherit;
  }
}