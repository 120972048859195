@import '../../styles/colors.scss';

.LoadingFullscreen {
  display: grid;
  height: 100vh;
  width: 100%;
  background-color: $primary;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: 
    '. . .'
    '. heart .'
    '. . .';
  position: absolute;
  top: 0px;
  left: 0px;
}

.LoadingFullscreen.white {
  background-color: $primary-light;
}

.LoadingFullscreen.tiny {
  height: 36px;
  width: inherit;
  position: inherit;
  top: inherit;
  left: inherit;
  font-size: 0.3em;
}

.LoadingFullscreen.tiny p {
  display: none;
}

.LoadingFullscreen i, .LoadingFullscreen .heart {
  grid-area: heart;
  justify-self: center;
  align-self: center;
  color: $primary-dark;
  animation: pulse 1s ease 0s infinite alternate;
}

.LoadingFullscreen p {
  grid-area: heart;
  justify-self: center;
  align-self: flex-end;
  color: $primary-dark;
}

@keyframes pulse {
  from {
    font-size: 1em;
  }
  to {
    font-size: 5em;
  }
}