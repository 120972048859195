@import '../../styles/colors.scss';

.Wedding {
  display: grid;
  max-width: 100%;
  row-gap: 1em;

  font-family: 'Dancing Script';
  font-family: 'Roboto';
  font-style: italic;
}

.Wedding > h2 {
  font-size: 1.3em;
  justify-self: center;
}

.Wedding .event {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 100%;
}

.Wedding .event img {
  max-width: 100%;
  border-radius: 0.5em;
}

.Wedding .event .agenda {
  display: grid;
  grid-template-rows: 50px 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas:
    'a a a a a'
    'a a a a a'
    '. m m m m'
    '. m m m m';
}

.Wedding .event .agenda ul {
  grid-row: 2/5;
  grid-column: 1/6;
  margin: 0;
}

.Wedding .event .agenda p {
  grid-area: a;
  padding-left: 8px;
  margin: 0;
  align-self: start;
}

.Wedding .event .agenda .embed-container {
  grid-area: m;
  position: relative; 
  padding-bottom: 56.25%; /* ratio 16x9 */
  height: 0; 
  overflow: hidden; 
  width: 100%;
  height: auto;
}

.Wedding .event .agenda .embed-container iframe {
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
}

.Wedding .event h2 {
  font-family: 'Dancing Script';
}

@supports not (-webkit-touch-callout: none) {
  @media only screen and (min-width: 755px) {
    .Wedding {
      padding-left: 8em;
      padding-right: 8em;
    }
  }
}

@media only screen and (min-width: 755px) {
  .Wedding {
    grid-template-rows: 1.5em min-content min-content;
  }
  .Wedding .event {
    grid-template-rows: min-content 1fr;
  }
  .Wedding .event img {
    grid-row: 2/3;
  }
  
  .Wedding .event .agenda {
    grid-row: 2/3;
  }
  .Wedding .event h2 {
    grid-column: 1/3;
    justify-self: start;
  }
}

@media only screen and (max-width: 755px) {
  .Wedding .event {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 100%;
  }
  .Wedding .event .agenda {
    display: grid;
    grid-template-rows: 50px 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      'a a a a a'
      '. . . . .'
      'm m m m m'
      'm m m m m';
  }
  .Wedding .event .agenda .embed-container {
    top: -20%;
  }
}