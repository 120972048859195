@import '../../styles/colors.scss';

.Gifts {
  display: grid;
}

.Gifts > div {
  padding-top: 120px;
  justify-self: center;
  font-size: 5em;
  font-family: 'Oswald', sans-serif;
}