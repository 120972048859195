$background-color: #ffffff;

$primary: #fce4ec;
$primary-light: #ffffff;
$primary-dark: #c9b2ba;
$primary-text: #e2b007;
$primary-text-light: #fff;
$primary-text-dark: rgb(168, 168, 168);

$secondary: #e2b007;
$secondary-light: #e2b007;
$secondary-dark: #e2b007;
$secondary-text: #fff;
$secondary-text-light: #fff;
$secondary-text-dark: rgb(228, 228, 228);

$flower-text-color: #e2b007;

$white-text: #a0a0a0;
$white-text-hover: #e2b007;
$white-text-current: #e2b007;