@import '../../styles/colors.scss';

.heartContainer {
  display: grid;
  grid-template-rows: max-content;
  grid-template-columns: max-content;
}

.heartContainer > img {
  grid-row: 1/2;
  grid-column: 1/2;
  align-self: stretch;
  justify-self: stretch;
}
.heartContainer > span {
  grid-row: 1/2;
  grid-column: 1/2;
  align-self: center;
  justify-self: center;
  font-weight: bold;
  color: $primary-text;
  position: relative;
  top: -5%;
}