@import '../../styles/colors.scss';

.StatusBar {
  color: #fff;
  background-color: $primary;
  width: 100%;
  height: 44px;
  position: fixed;
  top: 0px;
  z-index: 12;
  display: grid;
  grid-template-columns: 1fr min-content 8px;
  align-items: center;
}

.StatusBar i {
  grid-row: 1/2;
  grid-column: 1/2;
  align-self: center;
  justify-self: center;
}

.StatusBar h1 {
  padding: 0;
  margin: 0;
  justify-self: center;
  grid-column: 1/4;
  grid-row: 1/2;
  font-size: 1.5em;
  font-family: 'Parisienne', cursive;
  color: $flower-text-color;
}

.StatusBar button {
  grid-column: 2/3;
  grid-row: 1/2;
  color: $primary-text;
  border-color: $primary-text;
}

@supports (-webkit-touch-callout: none) {
  .StatusBar h1 {
    padding: 0;
    margin: 0;
    justify-self: center;
    grid-column: 1/4;
    grid-row: 1/2;
  }
  @media only screen and (display-mode: standalone) {
    .StatusBar {
      padding-top: 44px;
    }
  }
}

@media only screen and (orientation: landscape) {
  .StatusBar {
    display: none;
  }
}

@media only screen and (min-width: 755px) {
  .StatusBar {
    display: none;
  }
}