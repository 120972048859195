@import '../../styles/colors.scss';

.StoryMain {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.StoryMain .year {
  display: flex;
  flex-direction: column;
  row-gap: 0.5em;
}

.StoryMain .year > h1 {
  font-size: 2em;
  font-weight: bold;
  text-align: center;

  font-family: 'Dancing Script';
}

.StoryMain .year > .entry {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1em;
}

.StoryMain .year > .entry .date {
  grid-column: 1/2;
  grid-row: 1/2;
}

.StoryMain .year > .entry img {
  width: 80%;
  grid-column: 1/2;
  grid-row: 1/2;
  border-radius: 0.5em;
  opacity: 0;
  justify-self: end;
}

.StoryMain .year > .entry .text {
  color: rgb(255, 255, 255);
  grid-column: 1/2;
  grid-row: 1/2;
  border-radius: 0.5em;
  padding: 0.5em;
  background-color: rgba(0, 0, 0, 0.233);
  align-self: start;
  position: relative;
  left: 15%;
  top: 12.5%;
  opacity: 0;
  font-family: 'Roboto';
  font-style: italic;
  // font-weight: 300;
}

.StoryMain .year > .entry .text h2 {
  font-family: 'Dancing Script';
}

.StoryMain .year > .entry .right {
  grid-column: 2/3;
}

.StoryMain .year > .entry img.right {
  width: 110%;
  justify-self: start;
  position: relative;
  left: -20%;
}

.StoryMain .year > .entry .text.right {
  position: relative;
  left: -15%;
  top: 12.5%;
}

@keyframes slide-in {
  0%   {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0%   {
    transform: translateX(200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0%   {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media only screen and (max-width: 925px) {
  .StoryMain .year > .entry .text h2 {
    font-size: 1.5em;
  }
  
  .StoryMain .year > .entry .text p {
    font-size: 0.8em;
  }

  .StoryMain .year > .entry .text {
    top: 0px;
    align-self: center;
  }
  .StoryMain .year > .entry .text.right {
    top: 0px;
  }
}

@media only screen and (max-width: 755px) {
  .StoryMain .year > .entry {
    display: grid;
    grid-template-columns: 1fr;
  }
  .StoryMain .year > .entry .right {
    grid-column: 1/2;
  }

  .StoryMain .year > .entry img {
    width: 100%;
    justify-self: center;
  }
  
  .StoryMain .year > .entry img.right {
    width: 100%;
    justify-self: center;
    left: 0;
  }

  .StoryMain .year > .entry .text {
    justify-self: center;
    left: 0;
    top: 0;
    align-self: end;
  }
  .StoryMain .year > .entry .text.right {
    left: 0;
    top: 0;
  }
}