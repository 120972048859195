.Login {
  display: grid;
  justify-items: center;
  align-content: flex-start;
  grid-template-rows: 1fr 3fr 4fr;
}

.Login .content {
  grid-row: 2/3;
  align-self: flex-start;
  // width: 80%;
}

@media only screen and (min-width: 755px) {
  .Login {
    display: grid;
    justify-items: center;
    align-content: center;
  }
}